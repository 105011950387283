
import React, { useState } from "react";
import styles from "./StepComponent.module.css";

interface Step {
  id: number;
  title: string;
  details: string;
}

interface StepComponentProps {
  steps: Step[];
  activeStep: number ;
  onStepChange: (stepId: number) => void;
}

const StepComponent: React.FC<StepComponentProps> = ({
  steps,
  activeStep,
  onStepChange,
}) => {
  const handleStepClick = (id: number) => {
    onStepChange(activeStep === id ? -1 : id);
  };

  return (
    <div className={styles.container}>
      {/* Horizontal Progress Bar */}
      <div className={styles.progressBarContainer}>
        <div className={styles.progressBar}></div>
        <div
          className={styles.progressIndicator}
          style={{
            width: `${((activeStep || 0) / (steps.length )) * 100}%`,
          }}
        ></div>
        {steps.map((step, index) => (
          <div
            key={step.id}
            className={`${styles.stepMarker} ${
              index <= (activeStep || -1) ? styles.completed : ""
            } ${activeStep === index ? styles.active : ""}`}
            onClick={() => handleStepClick(index+1)}
          >
              {index+1}
          </div>
        ))}
      </div>

      {/* Steps Details */}
      <div className={styles.stepsContainer}>
        {steps.map((step) => (
          <div key={step.id} className={styles.stepWrapper}>
            <div
              className={`${styles.stepTitle} ${
                activeStep === step.id ? styles.activeStep : ""
              }`}
              onClick={() => handleStepClick(step.id)}
              style={{
                background:"#4682B4" , 
                color: "#fff", // Ensure the text is readable
                borderRadius: "5px", // Add some rounded corners
                padding: "10px 20px", // Add padding for better spacing
                cursor: "pointer", // Make it clear that it's clickable
                boxShadow: activeStep === step.id ? "0px 4px 10px rgba(0, 0, 0, 0.3)" : "none", // Add shadow to active step
                transition: "background 0.3s, transform 0.3s", // Smooth transitions for hover effects
                transform: activeStep === step.id ? "scale(1.05)" : "scale(1)", 
                textAlign:"left"
              }}
            >
              {step.title}
            </div>
            {activeStep === step.id && (
              <div className={styles.stepDetails}>{step.details}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepComponent;



