
import React from 'react';
import DynamicPage from './DynamicPage';


interface Step {
  id: number;
  title: string;
  details: string;
  image: string;
}

const steps: Step[] = [
  {
    id: 1,
    title: 'Search for PetCarians Near You',
    details:
      'Start by searching for professional PetCarians in your area. Enter your location, and we will show you a list of verified and trusted petcarians nearby. You can check their profiles and reviews to make sure you find the right one for your pet.',
    image: require("../assets/Home.png"),
  },
  {
    id: 2,
    title: 'Select the Best PetCarians for Your Pet',
    details:
      'Choose the PetCarians who best suits your pet’s needs. Look at their expertise, availability, and ratings from other pet parents. You can filter your search by experience, specialty, and more, to make sure your pet gets the best care possible.',
    image: require("../assets/SearchByLocation2-portrait.png"),
  },
  {
    id: 3,
    title: 'Enter the Offer You Are Willing to Pay.',
    details:
      'Set your budget by selecting the amount you are willing to pay for pet sitting services. We offer flexible pricing options to fit every budget, whether you need a few hours of care or extended services. Get transparency on the cost before confirming your booking.',
    image: require("../assets/SearchByLocation3-portrait.png")
  },
  {
    id: 4,
    title: 'Review Your Booking Details',
    details:
      'Carefully review the details of your booking before confirmation. Verify the selected PetCarians, the pet information, and the service dates. Make sure everything looks good before proceeding to confirm your booking.',
    image: require("../assets/SearchByLocation4-portrait.png"),
  }  
];

const featureBoxes = [
  { icon: '📍', title: 'Easy Location Search', description: 'Find PetCarians near by your location. Our system ensures accurate results for your area'},
  { icon: '✨', title: 'Verified Professionals', description: 'Connect with highly rated and verified carers to ensure your pet’s safety and comfort.' },
  { icon: '💳', title: 'Flexible Payment Options', description:' Choose a payment option that suits you. Transparent pricing with no hidden costs.'},
];

const SearchByLocationPage: React.FC = () => {
  return (
    <DynamicPage
      headerTitle="How to Book a Pet Sitting Service"
      headerDescription="Follow the steps below to book a trusted and professional pet sitter in just a few clicks."
      steps={steps}
      featureBoxes={featureBoxes}
    />
  );
};

export default SearchByLocationPage;
