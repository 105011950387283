import DynamicPage from './DynamicPage';
import phone1 from '../assets/Home.png';
import phone2 from '../assets/phoneView1.jpg';

const steps=[
    {
      id: 1,
      title: 'Search for PetCarians in Your Contacts',
      details:
        'Begin by looking through your contacts for friends who are pet lovers and willing to help. Reach out to them directly or use our platform to see if they’re available for pet-sitting. Make sure to discuss their preferences and availability beforehand.',
      image: phone1,
    },
    {
      id: 2,
      title: 'Select the Best PetCarians Friend for Your Pet',
      details:
        'Choose the friend who can best take care of your pet based on their experience with animals, trustworthiness, and availability. Consider how comfortable your pet will be with them, and communicate any specific needs or preferences your pet might have.',
      image: require("../assets/SearchByLocation2-portrait.png")
    },
    {
      id: 3,
      title: 'Set the Amount You’re Willing to Pay',
      details:
        'Decide on the amount you’re comfortable offering your friend for their help. Whether it’s a token of appreciation or a more significant payment, ensure both parties are clear about the arrangement. Agree on the terms before proceeding.',
      image: require("../assets/SearchByLocation3-portrait.png")
    },
    {
      id: 4,
      title: 'Review Your Booking Details',
      details:
        'Carefully review the details of your booking before confirmation. Verify the selected PetCarians, the pet information, and the service dates. Make sure everything looks good before proceeding to confirm your booking.',
      image: require("../assets/SearchByLocation4-portrait.png"),
    }  
  ];
  

  const featureBoxes = [
    {
      icon: '📞',
      title: 'Seamless Contact Search',
      description:
        'Quickly connect with your trusted contacts who love pets and are ready to help. Our platform simplifies finding the perfect PetCarians from your circle.',
    },
    {
      icon: '🤝',
      title: 'Personalized Trust',
      description:
        'Rely on the friends you trust the most to care for your pets. This approach ensures a familiar and comforting environment for your furry companions.',
    },
    {
      icon: '💳',
      title: 'Convenient Payment Options',
      description:
        'Easily settle payments with your friends using flexible and secure payment options. Transparent and hassle-free for both parties.',
    },
  ];
  

const SearchInCircle =() => {
  return (
    <DynamicPage
    headerTitle="How to Find the Perfect PetCarians from Your Contacts"
    headerDescription="Follow these simple steps to connect with trusted friends for your pet care needs."
    steps={steps}
    featureBoxes={featureBoxes}
  />  
  );
};

export default SearchInCircle;
