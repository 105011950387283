import React, { useEffect } from 'react';
import { Typography, Box, Button, useMediaQuery } from '@mui/material';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import styles from './WelcomePage.module.css'; 
import heroImage1 from '../assets/PetCarians1.jpg';
import heroImage2 from '../assets/PetCarians2.jpg';
import heroImage11 from '../assets/PetCarians11.jpg';
import heroImage7 from "../assets/PetCarians7.jpg";
import phone1 from "../assets/Home1-portrait.png";
import mobile from "../assets/heroMobile.jpg";
import mobile1 from "../assets/heroMobile1.png";
import mobile2 from "../assets/heroMobile2.jpg";
import ConcentricCircles from './Circles';
import { useNavigate } from 'react-router-dom';


const WelcomePage: React.FC = () => {
    const isMobile = useMediaQuery("(max-width:600px)");
    const navigate = useNavigate();
    const [arrayImage,setArrayImage] = React.useState<string[]>([]);
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false, // Hides arrows for cleaner navigation
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const heroImages = [heroImage1, heroImage2, heroImage7,heroImage11];

  const heroImagesMobile = [mobile,mobile1,mobile2];

  useEffect(() => {
    if (isMobile) {
      setArrayImage(heroImagesMobile);
    } else {
      setArrayImage(heroImages);
    }
  }, []);


  return (

    <Box
  className={styles.pageContainer}
  display="flex"
  flexDirection="column"
  minHeight="100vh"
  justifyContent="space-between"
>
  <Box className={styles.mainContainer}>
    {/* Hero Section with Slider */}
    <Typography 
  variant="h2" 
  component="h1" 
  className={styles.heroTitle} 
  sx={{
    fontWeight: 'bold',
    textAlign: 'center',
    paddingTop: '2rem',
  }}
>
  Welcome to <span className={styles.highlight}>PetCarians</span>
</Typography>

<Typography 
  variant="h5" 
  component="h2" 
  className={styles.heroSubtitle} 
  sx={{
    textAlign: 'center',
    marginBottom: '1rem',
  
  }}
>
  Your trusted community of pet care professionals.
</Typography>
    <Box position="relative" height="95vh">
    <Slider {...sliderSettings} className={styles.slider}>
  { arrayImage.map((image, index) => (
    <Box
      key={index}
      className={styles.heroSlide}
      sx={{
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',       // Ensures the image is cropped proportionally. // Prevents tiling of smaller images.
        backgroundPosition: 'center',  // Centers the image in the viewport.
        width: '100%',                 // Full viewport width.
        height: '80vh',                // Maintains a 16:9 aspect ratio across all devices.
      }}
    />
  ))}
</Slider>
    </Box>

    {/* Service Section */}
    <Box className={styles.serviceContainer}>
      <Typography variant="h3" component="h3"> 
         <span className={styles.highlight}>PetCarians</span> Services
        </Typography>
      

            
        <Box className={`${isMobile ? styles.serviceBoxReverse+" "+ styles.serviceBox : styles.serviceBox }`}>
        <Box className={styles.serviceItem}>  
          <ConcentricCircles activeStep={Number(0)} imageSrc={phone1} />
          </Box>
          <Box className={styles.serviceText}>
            <Typography variant="h4" component="h4" gutterBottom> 
              Connect with <span className={styles.highlight}>PetCarians</span> in Your area
              </Typography> 
              <Typography variant="body1" component="p" gutterBottom>
                Our platform connects you with trusted pet care professionals in your local community, so you can rest easy knowing your pets are in safe hands.
                </Typography>
                <Box className={styles.exploreButtonContainer}>
                <Button variant="contained" className={styles.exploreButton} onClick={() => navigate("/serachByLocation")}>Explore More</Button>
                </Box>
                
          </Box>
        
                    
        </Box>
        <Box className={styles.serviceBox}>
       
          <Box className={styles.serviceText}>
            <Typography variant="h4" component="h4" gutterBottom> 
              Connect with <span className={styles.highlight}>PetCarians</span> in Your Circle
              </Typography> 
              <Typography variant="body1" component="p" gutterBottom>
                Our platform let you connect with trusted pet care professionals from within your personal or social network.
                </Typography>
                <Typography variant="body1" component="p" gutterBottom>
                Effortlessly connect with trusted PetCarians nearby, explore their profiles, and choose a sitter who shares your commitment to the care and safety of your pets.
                </Typography>
                <Box className={styles.exploreButtonContainer}>
                <Button variant="contained" className={styles.exploreButton} onClick={() => navigate("/serachInCircle")}>Explore More</Button>
                </Box>
                
          </Box>
          <Box className={styles.serviceItem}>  
          <ConcentricCircles activeStep={Number(0)} imageSrc={phone1} />
          </Box>
          

        </Box>
      
        <Box className={`${isMobile ? styles.serviceBoxReverse+" "+ styles.serviceBox : styles.serviceBox }`}>
        <Box className={styles.serviceItem}>  
          <ConcentricCircles activeStep={Number(0)} imageSrc={phone1} />
          </Box>
          <Box className={styles.serviceText}>
            <Typography variant="h4" component="h4" gutterBottom> 
               Become a <span className={styles.highlight}>PetCarian</span>
              </Typography> 
              <Typography variant="body1" component="p" gutterBottom>
  Join our community of trusted pet care professionals to Care for furry friends, build connections, and earn while doing what you love.
</Typography>
<Typography variant="body1" component="p" gutterBottom>
  Showcase your skills, expertise, and passion for pet care to Connect with pet owners seeking reliable and compassionate care, and Earn as you grow your career in pet care.
</Typography>

                <Box className={styles.exploreButtonContainer}>
                <Button variant="contained" className={styles.exploreButton} onClick={() => navigate("/bePetCarian")}>Explore More</Button>
                </Box>
                
          </Box>
        

        </Box>
    </Box>
  </Box>
</Box>
  );
};

export default WelcomePage;


