import React from 'react';
import { Box, Typography} from '@mui/material';
import './DeletePage.css';


const DeletePage: React.FC = () => {
  return (

    <Box display="flex" flexDirection="column"  justifyContent="space-between" >
        <Box className="content-delete">
          <Box className="content-text-delete">
            <Typography variant="h6" gutterBottom align="left">
              Account Removal Request
            </Typography>
            <Typography variant="body2" component="p" gutterBottom align="left">
              Last Updated: 1st November 2024
            </Typography>

            <Typography variant="body1" component="p" gutterBottom align="left">
              We understand that you may wish to remove your account from PetCarians. To facilitate this process, we kindly request you to reach out to us at
              <strong> admin@petcarians.com.au </strong> with the following details:
            </Typography>

            <Typography variant="body1" component="p" gutterBottom align="left">
              - The full name and email address associated with your account
            </Typography>
            <Typography variant="body1" component="p" gutterBottom align="left">
              - Your registered mobile number
            </Typography>
            <Typography variant="body1" component="p" gutterBottom align="left">
              - The reason for the account removal
            </Typography>

            <Typography variant="body1" component="p" gutterBottom align="left">
              Our team will then contact you to verify your identity and process your account removal request. Please include <strong>"Account Removal Request"</strong> in the subject line of the email.
            </Typography>

            <Typography variant="body1" component="p" gutterBottom align="left">
              Upon successful removal of your account, we will send you a confirmation email. Please note that the account removal process typically takes between 7 to 10 working days.
            </Typography>

            <Typography variant="body1" component="p" gutterBottom align="left">
              We would like to inform you that once your account is removed, you will not be able to recover it. Should you wish to use our services in the future, you will need to create a new account with PetCarians.
            </Typography>

            <Typography variant="body1" component="p" gutterBottom align="left">
              We appreciate your understanding and cooperation in this matter.
            </Typography>
          </Box>

        </Box>
   


    </Box>
  );
};

export default DeletePage;