import React, { useState } from 'react';
import { Box, Typography, Container, TextField, Button, CircularProgress } from '@mui/material';
import emailjs from 'emailjs-com';
import './ContactPage.css';

const ContactPage: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const serviceId = 'service_csq0xkq';
  const templateId = 'template_n6gnta8';  
  const publicKey = 'cQ-Z_izqCf7e9i3X6';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true); // Start loading

    emailjs
      .send(
        serviceId,
        templateId,
        {
          user_name: formData.name,
          user_email: formData.email,
          message: formData.message,
        },
        publicKey
      )
      .then((result) => {
        console.log('Email sent successfully:', result.text);
        setIsSubmitted(true);
        setFormData({ name: '', email: '', message: '' });
        setLoading(false); // Stop loading
      })
      .catch((error) => {
        console.error('Error sending email:', error.text);
        setLoading(false); // Stop loading
      });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      justifyContent="space-between"
    >
      <Box className="main-container-contact">
        <Box className="content-contact">
          <Box className="content-text-contact">
            <Typography variant="h6" component="h1" gutterBottom>
              Need Assistance? We're Here to Help
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
              If you require any assistance with your PetCarians account or have
              any questions about our services, we're always here to support
              you! Whether you have a question about your account, need help
              with a feature, or just want to provide feedback, don't hesitate
              to reach out.
            </Typography>
          </Box>
        </Box>
        <Box mt={4} p={3} className="contact-form" >
          <Typography variant="h5" component="h2" gutterBottom color="#34567A" textAlign="center">
            Get in Touch
          </Typography>
          <Typography
            variant="body1"
            textAlign="center"
            color="text.secondary"
            mb={3}
          >
            Fill out the form below, and we’ll get back to you as soon as possible.
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Full Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              sx={{ bgcolor: '#f9f9f9', borderRadius: 1 }}
              InputLabelProps={{ style: { color: '#34567A' } }}
            />
            <TextField
              label="Email Address"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              sx={{ bgcolor: '#f9f9f9', borderRadius: 1 }}
              InputLabelProps={{ style: { color: '#34567A' } }}
            />
            <TextField
              label="Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
              required
              sx={{ bgcolor: '#f9f9f9', borderRadius: 1 }}
              InputLabelProps={{ style: { color: '#34567A' } }}
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={loading} 
              sx={{
                mt: 2,
                bgcolor: '#34567A',
                color: '#fff',
                fontWeight: 'bold',
                textTransform: 'none',
                '&:hover': { bgcolor: '#274B6D' },
              }}
            >
              {loading ? (
                <CircularProgress size={24} sx={{ color: '#fff' }} />
              ) : (
                'Submit'
              )}
            </Button>
          </form>
          {isSubmitted && (
            <Box
              mt={2}
              p={2}
              textAlign="center"
              sx={{
                bgcolor: 'success.light',
                color: 'success.contrastText',
                borderRadius: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
              }}
            >
              <Typography variant="body1">
                Thank you! Your message has been sent.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ContactPage;


