import DynamicPage from './DynamicPage';


const steps = [
  {
    id: 1,
    title: 'Download PetCarians',
    details:
      'Get started by downloading the PetCarians app from the App Store or Google Play Store. It’s quick, free, and the first step toward connecting with pet parents in need of care services.',
    image: require("../assets/bePetCarians1-portrait.png"),
  },
  {
    id: 2,
    title: 'Sign Up',
    details:
      'Create an account by providing your details. Set up your profile, including your experience with pets, availability, and any specialties you offer. A great profile helps pet parents choose you with confidence.',
    image: require("../assets/bePetCarians2-portrait.png"),
  },
  {
    id: 3,
    title: 'Become a PetCarians',
    details:
      "Once you've signed up, click on 'Become a PetCarians' to set up your profile. Specify details such as your service rates, available options like pickup and drop-off, and any special offerings to attract pet parents.",
    image: require("../assets/bePetCarians3-portrait.png"),
  },
  {
    id: 4,
    title: 'Start Receiving Booking Requests',
    details:
      'Once your profile is complete, pet parents can find and request your services. Stay attentive to booking requests, communicate clearly, and prepare to provide excellent care for their pets!',
    image: require("../assets/bePetCarians4-portrait.png"),
  },
];

  
  
  const featureBoxes = [
    {
      icon: '🐾',
      title: 'Love Pets Without Owning One',
      description:
        'If you’re a pet lover but can’t keep a permanent pet, pet sitting is the perfect way to enjoy their company while helping others. Connect with pet parents and create meaningful bonds with furry friends.',
    },
    {
      icon: '📲',
      title: 'Convenient App Experience',
      description:
        'Easily manage your bookings, communicate with pet owners, and receive payments, all through our user-friendly app designed to make pet sitting simple and efficient.',
    },
    {
      icon: '💼',
      title: 'Turn Passion Into Income',
      description:
        'Earn money doing what you love! Provide excellent care to pets while enjoying the flexibility of setting your own schedule and rates.',
    },
  ];
  
  

const BePetCarians=()=>{
  return (
    <DynamicPage
    headerTitle="How to Become a PetCarians and Start Your Pet Sitting Journey"
    headerDescription="Follow these easy steps to join PetCarians and start providing exceptional pet care services."
    steps={steps}
    featureBoxes={featureBoxes}
  />  
  );
};

export default BePetCarians