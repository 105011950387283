import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import styles from './DynamicPage.module.css';
import StepComponent from './StepComponent';
import ConcentricCircles from './Circles';

interface Step {
  id: number;
  title: string;
  details: string;
  image: string;
}

interface DynamicPageProps {
  headerTitle: string;
  headerDescription: string;
  featureBoxes: { icon: string; title: string; description: string }[];
  steps: Step[];
}

const DynamicPage: React.FC<DynamicPageProps> = ({
  headerTitle,
  headerDescription,
  featureBoxes,
  steps,
}) => {
  const [activeStep, setActiveStep] = useState<number>(1);

  return (
    <Box display="flex" flexDirection="column" className={styles.pageContainer}>
      <Box className={styles.stepsHeader}>
        <Typography variant="h4" component="h1">
          {headerTitle}
        </Typography>
        <Typography variant="body1" component="p">
          {headerDescription}
        </Typography>
      </Box>

      <Box className={styles.enhancedSection}>
        {featureBoxes.map((box, index) => (
          <Box key={index} className={styles.featureBox}>
            <Typography variant="h6" className={styles.featureTitle}>
              <span className={styles.icon}>{box.icon}</span> {box.title}
            </Typography>
            <Typography variant="body2" className={styles.featureDescription}>
              {box.description}
            </Typography>
          </Box>
        ))}
      </Box>

      <Box className={styles.ContainerForStep}>
        <StepComponent steps={steps} activeStep={activeStep} onStepChange={setActiveStep} />
        <Box className={styles.circlesContainer}>
          <ConcentricCircles activeStep={Number(activeStep)}  imageSrc={steps[activeStep-1].image}/>
        </Box>
      </Box>
    </Box>
  );
};

export default DynamicPage;
