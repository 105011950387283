import React, { useEffect, useState } from 'react';
import styles from './ConcentricCircles.module.css';
import photo from '../assets/Home.png';

interface ConcentricCirclesProps {
  activeStep: number;
  imageSrc: string;
}

const ConcentricCircles: React.FC<ConcentricCirclesProps> = ({ activeStep,imageSrc  }) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(false); // Reset the animation
    const timer = setTimeout(() => setAnimate(true), 10); // Small delay to trigger animation again
    return () => clearTimeout(timer);
  }, [activeStep]); // Trigger animation whenever activeStep changes

  return (
    <div className={styles.circleContainer}>
      <div
        className={`${styles.circleLarge} ${animate ? styles.animate : ''}`}
      ></div>
      <div
        className={`${styles.circleMedium} ${animate ? styles.animate : ''}`}
      ></div>
      <div
        className={`${styles.circleSmall} ${animate ? styles.animate : ''}`}
      ></div>
      <img src={imageSrc} alt="Concentric Circles"  className={`${styles.photo} ${animate ? styles.animatePic : ''}`}/>
    </div>
  );
};

export default ConcentricCircles;



