import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WelcomePage from './components/WelcomePage';
import PolicyPage from './components/PolicyPage';
import ContactPage from './components/ContactPage';
import DeletePage from './components/DeletePage';
import Header from './components/Header';
import Footer from './components/Footer';
import SerachByLocation from './components/SerachByLocation';
import ScrollToTop from './customHook/ScrollToTop';
import SearchInCircle from './components/SerachInCircle';
import BePetCarians from './components/BePetCarians';

const App: React.FC = () => {
  const [showCard, setShowCard] = React.useState<boolean>(false);

  return (
    <div className="App">
      <Router>
      <ScrollToTop />
        <Header
          hasHiddenAuthButtons={false}
          showCardFunction={(show) => setShowCard(show)}
        />
            <div className="content">

        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/policy" element={<PolicyPage />} />
          <Route path="/support" element={<ContactPage />} />
          <Route path="/removeaccount" element={<DeletePage />} />
          <Route path="/serachByLocation" element={<SerachByLocation />} />
          <Route path="/serachInCircle" element={<SearchInCircle />}/>
          <Route path="/bePetCarian" element={<BePetCarians />} />
        </Routes>
        </div>
        <Footer />

      </Router>
    </div>
  );
};

export default App;
